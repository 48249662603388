import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import "./init"
import elementUI from "element-ui";
import "./assets/style.scss"
import 'mavon-editor/dist/css/index.css'
import VueHighlightJS from 'vue-highlightjs'
import mavonEditor from "mavon-editor"
import dayjs from "dayjs"

Vue.use(elementUI)
Vue.use(mavonEditor)
Vue.use(VueHighlightJS)
Vue.prototype.$dayJS = dayjs;
Vue.prototype.$imgBasePath = "http://file.xmxui.com/";
Vue.config.productionTip = false
import 'element-ui/lib/theme-chalk/index.css';

new Vue({
  router,
  render: h => h(App)
}).$mount("#app")
